<template>
    <div>
        <HRetribusi />
        <panel
            title="SURAT PEMBERITAHUAN RETRIBUSI DAERAH"
            class="panel panel-success"
        >
            <div class="card mb-2">
                <div class="card-body">
                    <div class="nav-wizards-container">
                        <nav class="nav nav-wizards-1 mb-2">
                            <!-- completed -->
                            <div class="nav-item col">
                                <b-nav-item
                                    :class="{
                                        'nav-link': true,
                                        active: activeVar.isActive1,
                                        completed: completeVar.isComplete1,
                                        disabled: disabledVar.isDisabled1
                                    }"
                                >
                                    <div class="nav-no">1</div>
                                    <div class="nav-text">
                                        PENDAFTARAN WAJIB RETRIBUSI
                                    </div>
                                </b-nav-item>
                            </div>

                            <!-- active -->
                            <div class="nav-item col">
                                <b-nav-item
                                    :class="{
                                        'nav-link': true,
                                        active: activeVar.isActive2,
                                        completed: completeVar.isComplete2,
                                        disabled: disabledVar.isDisabled2
                                    }"
                                >
                                    <div class="nav-no">2</div>
                                    <div class="nav-text">
                                        JENIS RETRIBUSI DAN MASA RETRIBUSI
                                    </div>
                                </b-nav-item>
                            </div>

                            <!-- disabled -->
                            <div class="nav-item col">
                                <b-nav-item
                                    :class="{
                                        'nav-link': true,
                                        active: activeVar.isActive3,
                                        completed: completeVar.isComplete3,
                                        disabled: disabledVar.isDisabled3
                                    }"
                                    to="/menu/sptrd/pendaftaran/step-3"
                                >
                                    <div class="nav-no">3</div>
                                    <div class="nav-text">
                                        RINCIAN RETRIBUSI
                                    </div>
                                </b-nav-item>
                            </div>

                            <div class="nav-item col">
                                <b-nav-item
                                    :class="{
                                        'nav-link': true,
                                        active: activeVar.isActive4,
                                        completed: completeVar.isComplete4,
                                        disabled: disabledVar.isDisabled4
                                    }"
                                >
                                    <div class="nav-no">4</div>
                                    <div class="nav-text">SELESAI</div>
                                </b-nav-item>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-body">
                    <router-view />
                </div>
            </div>
        </panel>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "@/components/npwr/HRetribusi.vue";

export default {
    components: {
        HRetribusi
    },
    data() {
        return {
            activeVar: {
                isActive1: false,
                isActive2: false,
                isActive3: false,
                isActive4: false
            },
            completeVar: {
                isComplete1: false,
                isComplete1: false,
                isComplete1: false,
                isComplete1: false
            },
            disabledVar: {
                isDisabled1: false,
                isDisabled2: false,
                isDisabled3: false,
                isDisabled4: false
            }
        };
    },
    created() {
        this.stepClass();
    },
    watch: {
        $route(to, from) {
            this.stepClass();
        }
    },
    methods: {
        stepClass() {
            switch (this.$route.name) {
                case "NextStep1":
                case "Step1":
                    // jika step 1 aktif
                    this.activeVar.isActive1 = true;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = false;
                    this.activeVar.isActive4 = false;
                    // jika step 1 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = false;
                    this.completeVar.isComplete3 = false;
                    this.completeVar.isComplete4 = false;

                    // jika step 1 disable
                    this.disabledVar.isDisabled1 = false;
                    this.disabledVar.isDisabled2 = false;
                    this.disabledVar.isDisabled3 = false;
                    this.disabledVar.isDisabled4 = false;
                    break;
                case "NextStep2":
                case "Step2":
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = true;
                    this.activeVar.isActive3 = false;
                    this.activeVar.isActive4 = false;

                    // jika step 2 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = false;
                    this.completeVar.isComplete3 = false;
                    this.completeVar.isComplete4 = false;

                    // jika step 2 disable
                    this.disabledVar.isDisabled1 = true;
                    this.disabledVar.isDisabled2 = false;
                    this.disabledVar.isDisabled3 = false;
                    this.disabledVar.isDisabled4 = false;
                    break;
                case "NextStep3":
                case "Step3":
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = true;
                    this.activeVar.isActive4 = false;

                    // jika step 2 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = true;
                    this.completeVar.isComplete3 = false;
                    this.completeVar.isComplete4 = false;

                    // jika step 2 disable
                    this.disabledVar.isDisabled1 = true;
                    this.disabledVar.isDisabled2 = true;
                    this.disabledVar.isDisabled3 = false;
                    this.disabledVar.isDisabled4 = false;
                    break;
                case "Step4":
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = false;
                    this.activeVar.isActive4 = true;

                    // jika step 2 complete
                    this.completeVar.isComplete1 = true;
                    this.completeVar.isComplete2 = true;
                    this.completeVar.isComplete3 = true;
                    this.completeVar.isComplete4 = true;

                    // jika step 2 disable
                    this.disabledVar.isDisabled1 = true;
                    this.disabledVar.isDisabled2 = true;
                    this.disabledVar.isDisabled3 = true;
                    this.disabledVar.isDisabled4 = true;
                    break;
                default:
                    this.activeVar.isActive1 = false;
                    this.activeVar.isActive2 = false;
                    this.activeVar.isActive3 = false;
                    this.activeVar.isActive4 = false;

                    // jika step 1 complete
                    this.completeVar.isComplete1 = false;
                    this.completeVar.isComplete2 = false;
                    this.completeVar.isComplete3 = false;
                    this.completeVar.isComplete4 = false;
            }
        }
    }
};
</script>
