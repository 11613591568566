var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('HRetribusi'),_c('panel',{staticClass:"panel panel-success",attrs:{"title":"SURAT PEMBERITAHUAN RETRIBUSI DAERAH"}},[_c('div',{staticClass:"card mb-2"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"nav-wizards-container"},[_c('nav',{staticClass:"nav nav-wizards-1 mb-2"},[_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{
                                    'nav-link': true,
                                    active: _vm.activeVar.isActive1,
                                    completed: _vm.completeVar.isComplete1,
                                    disabled: _vm.disabledVar.isDisabled1
                                }},[_c('div',{staticClass:"nav-no"},[_vm._v("1")]),_c('div',{staticClass:"nav-text"},[_vm._v(" PENDAFTARAN WAJIB RETRIBUSI ")])])],1),_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{
                                    'nav-link': true,
                                    active: _vm.activeVar.isActive2,
                                    completed: _vm.completeVar.isComplete2,
                                    disabled: _vm.disabledVar.isDisabled2
                                }},[_c('div',{staticClass:"nav-no"},[_vm._v("2")]),_c('div',{staticClass:"nav-text"},[_vm._v(" JENIS RETRIBUSI DAN MASA RETRIBUSI ")])])],1),_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{
                                    'nav-link': true,
                                    active: _vm.activeVar.isActive3,
                                    completed: _vm.completeVar.isComplete3,
                                    disabled: _vm.disabledVar.isDisabled3
                                },attrs:{"to":"/menu/sptrd/pendaftaran/step-3"}},[_c('div',{staticClass:"nav-no"},[_vm._v("3")]),_c('div',{staticClass:"nav-text"},[_vm._v(" RINCIAN RETRIBUSI ")])])],1),_c('div',{staticClass:"nav-item col"},[_c('b-nav-item',{class:{
                                    'nav-link': true,
                                    active: _vm.activeVar.isActive4,
                                    completed: _vm.completeVar.isComplete4,
                                    disabled: _vm.disabledVar.isDisabled4
                                }},[_c('div',{staticClass:"nav-no"},[_vm._v("4")]),_c('div',{staticClass:"nav-text"},[_vm._v("SELESAI")])])],1)])])])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('router-view')],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }